@import '@styles/variables';

.component {
  padding: 60px 35px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid var(--color-line);
  border-radius: 4px;
  text-align: center;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    padding-top: 45px;
    padding-bottom: 55px;
  }
}

.notFoundIcon {
  color: var(--color-line);
}

.heading {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--font-graphik-compact);
  line-height: 28px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    font-size: 32px;
    line-height: 32px;
  }
}

.description {
  margin-top: 8px;
}

.button {
  margin-top: 24px;
}
