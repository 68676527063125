.component {
  background: var(--color-black);
  color: var(--color-white);
}

.container {
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  max-width: 1568px;
  min-height: 100vh;

  @media screen and (min-width: 736px) {
    padding-right: 32px;
    padding-left: 32px;
  }

  @media screen and (min-width: 1472px) {
    padding-right: 64px;
    padding-left: 64px;
  }
}

.header {
  padding-top: 16px;
}

.headerMainContent {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (min-width: 736px) {
    flex-direction: row;
  }
}

.title {
  margin-top: 16px;
  max-width: 290px;
  font-size: 46px;
  font-weight: 700;
  line-height: 48px;

  @media screen and (min-width: 736px) {
    margin-top: 0;
  }

  @media screen and (min-width: 992px) {
    max-width: 660px;
    font-size: 96px;
    font-weight: 900;
    line-height: 80px;
  }
}

.logoWrapper {
  margin-top: 8px;
}

.logo {
  width: 96px;
  height: 18px;

  @media screen and (min-width: 736px) {
    width: 138px;
    height: 26px;
  }
}

.main {
  margin-top: 24px;
  padding-bottom: 50px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 736px) {
    margin-top: 64px;
    padding-bottom: 32px;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}

.button {
  margin-top: 16px;

  @media screen and (min-width: 992px) {
    margin-top: 24px;
  }
}

.img {
  margin-top: 50px;
  width: 283px;
  height: 297px;

  @media screen and (min-width: 736px) {
    margin-top: 0;
  }
}
