@import './variables';

:root {
  --sticky-header-height: 56px;
  --header-height: 120px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    --header-height: var(--sticky-header-height);
  }
}

* {
  -webkit-overflow-scrolling: touch;
}

html,
body {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  min-width: $mobile-window-width;
  height: 100%;
  font-size: 17px;
  font-family: var(--font-graphik);
  line-height: 24px;
  color: #000000;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

p {
  margin: 0;
  padding: 0;
}

button {
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: inherit;
  color: #000;

  &.e-button--themeTransparent:disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
}

input[type='text'] {
  appearance: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

body {
  /* 'react-remove-scroll' добавляет лишний паддинг
  https://s.csssr.ru/UB6FE13V2/Monosnap_file_7np6l.mp4 */
  padding-right: 0 !important;
}

/** Стили для лэйбла чекбокса со шрифтом 17px (по-умолчанию)
 *  Для ситуаций, когда шрифт меняется, в каждом конкретном случае прописываем line-height отдельно
 */
.e-checkboxText {
  line-height: 17px;
}
